
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="col-sm-12 col-md-12 mt-4">
              <div class="col-12 col-sm-12 col-md-12">
                <div class="row align-items-center">
                  <div class="col-sm-4 col-md-4 col-xl-3">
                    <div class="mb-3 position-relative">
                      <label class="d-inline align-items-center"> สาขา:</label>
                      <!-- :multiple="true"
                        :taggable="true"
                        :clear-on-select="false"
                        :close-on-select="false"
                        @tag="addTag" -->
                      <multiselect
                        v-model="branchIdSearch"
                        :options="localData"
                        label="nameTh"
                        :show-labels="false"
                      >
                      </multiselect>
                    </div>
                  </div>
                  <div class="col-sm-4 col-md-4 col-xl-3">
                    <div class="mb-3 position-relative">
                      <label class="d-inline align-items-center m-2 text-end">
                        ชื่อ:
                      </label>
                      <b-form-input
                        v-model="filter.name"
                        type="search"
                        placeholder="ชื่อ"
                        class="form-control"
                        @keyup.enter="handleSearch"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="col-sm-4 col-md-4 col-xl-3">
                    <div class="mb-3 position-relative">
                      <label class="d-inline align-items-center m-2 text-end">
                        นามสกุล:
                      </label>
                      <b-form-input
                        v-model="filter.familyName"
                        type="search"
                        placeholder="นามสกุล"
                        class="form-control"
                        @keyup.enter="handleSearch"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="col-sm-4 col-md-4 col-xl-3">
                    <div class="mb-3 position-relative">
                      <label class="d-inline align-items-center m-2 text-end">
                        เบอร์โทรศัพท์:
                      </label>
                      <b-form-input
                        v-model="filter.mobilePhone"
                        type="search"
                        placeholder="เบอร์โทรศัพท์"
                        class="form-control"
                        @keyup.enter="handleSearch"
                      ></b-form-input>
                    </div>
                  </div>

                  <div class="col-sm-6 col-md-8 col-xl-12 float-end text-end">
                    <btnComponent @click="handleSearch" changeStyle="search">
                    </btnComponent
                    >&nbsp;&nbsp;&nbsp;&nbsp;
                    <router-link :to="{ name: 'add-user' }">
                      <b-button
                        type="button"
                        variant="primary"
                        class="btn btn-primary float-end"
                      >
                        <i class="mdi mdi-plus me-1"></i>เพิ่มพนักงาน
                      </b-button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <!-- <p class="card-title-desc">รายชื่อพนักงาน</p> -->
            <div class="row mt-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    แสดงผล&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                      @input="PageChange"
                    ></b-form-select
                    >&nbsp;รายการ
                  </label>
                </div>
              </div>

              <div></div>
            </div>

            <b-skeleton-wrapper :loading="loading">
              <!-- skeleton  -->
              <template #loading>
                <b-skeleton-table></b-skeleton-table>
              </template>

              <!-- Table -->

              <tableData
                :fields="fields"
                :items="rowUser"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
              >
                <template #cell(index)="rowUser" v-if="this.currentPage > 1">
                  {{ rowUser.index + 1 + (currentPage * perPage - perPage) }}
                </template>
                <template #cell(index)="rowUser" v-else>
                  {{ rowUser.index + 1 }}
                </template>

                <!-- end edit table -->
                <template #cell(selected)="{ rowSelected }">
                  <template v-if="rowSelected">
                    <span aria-hidden="true">&check;</span>
                    <span class="sr-only">Selected</span>
                  </template>
                  <template v-else>
                    <span aria-hidden="true">&nbsp;</span>
                    <span class="sr-only">Not selected</span>
                  </template>
                </template>

                <!-- edit table -->
                <template v-slot:cell(action)="rowUser">
                  <router-link
                    class="px-2 text-primary"
                    v-b-tooltip.hover
                    title="Edit"
                    :to="{
                      name: 'update-user',
                      params: { userId: rowUser.item.userId },
                    }"
                  >
                    <i class="uil uil-pen font-size-18"></i>
                  </router-link>

                  <a
                    class="px-2 text-danger"
                    v-b-tooltip.hover
                    title="Delete"
                    @click="alert(rowUser.item.userId)"
                  >
                    <!-- uil-eye -->
                    <i class="uil uil-trash-alt font-size-18"></i>
                  </a>

                  <!-- {{ rowUser.item.userId }} -->
                </template>
              </tableData>
              <div class="row">
                <span
                  v-if="this.totalRecord === 0"
                  class="text-center font-size-16"
                  >ไม่พบรายการ</span
                >
                <div class="col" v-if="this.totalRecord > 0">
                  <div class="col">
                    <label class="d-inline-flex align-items-center">
                      หน้า {{ currentPage }} จาก {{ this.totalPage }} ทั้งหมด
                      {{ totalRecord }} รายการ
                    </label>
                    <div
                      class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-end
                      "
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="totalRecord"
                          :per-page="perPage"
                          @change="ChangePage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </b-skeleton-wrapper>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
import tableData from "@/components/tablecomponent";
import Multiselect from "vue-multiselect";
import btnComponent from "@/components/btnComponent.vue";

export default {
  page: {
    title: appConfig.users,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, tableData, Multiselect, btnComponent },

  data() {
    return {
      loading: undefined,

      title: appConfig.users,
      items: [
        {
          text: "ข้อมูลหลัก",
          active: true,
        },
        {
          text: "แผนกบุคคล",
          active: true,
        },
        {
          text: appConfig.users,
          active: true,
        },
      ],
      totalPage: "",
      branchId: [],
      userId: this.$route.params.userId,
      selectMode: "multi",
      selected: [],
      isLoading: "",
      to: "",
      total: "",
      from: "",
      totalRecord: "",
      rowUser: [],
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: [{ name: "", familyName: "", mobilePhone: "" }],
      filterOn: [],
      sortBy: "userId",
      sortDesc: false,
      selectedUsers: [],
      isSelectedAll: false,
      branchIdSearch: "",
      fields: [
        {
          key: "index",
          label: "ลำดับ",
        },

        {
          key: "name",
          label: "ชื่อ",
          sortable: true,
        },
        {
          key: "familyName",
          label: "นามสกุล",
          sortable: true,
        },
        {
          key: "mobilePhone",
          label: "เบอร์โทรศัพท์",
          sortable: true,
        },
        {
          key: "email",
          label: "อีเมล",
          sortable: true,
        },
        {
          key: "roleName",
          label: "สิทธิ์",
          sortable: true,
        },
        {
          key: "branchName",
          label: "สาขา",
          sortable: true,
        },
        {
          key: "action",
          label: appConfig.action,
        },
      ],
    };
  },

  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.rowUser.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.rowUser = this.items.length;
  },
  created() {
    this.getLocalData();
    this.getData();
  },
  methods: {
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.localData.push(tag);
      this.branchIdSearch.push(tag);
    },
    handleSearch() {
      if (this.branchIdSearch === "") {
        this.localDataBranch;
      } else {
        this.localDataBranch = [this.branchIdSearch.branchId];
      }
      this.getData();
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchItem = user.branchId;

      const localDataBranch = [];

      // const localBranchIdItem ={};
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });
      this.localDataBranch = localDataBranch;
    },
    onFiltered(filteredItems) {
      this.rowUser = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(rowUser) {
      this.selected = rowUser;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    // `${appConfig.api.baseURL}api/user/show`
    getData: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/user", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.localDataBranch,
            name: this.filter.name,
            familyName: this.filter.familyName,
            mobilePhone: this.filter.mobilePhone,
          },
        })
        .then((response) => {
          this.rowUser = response.data.data;
          const per_Page = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / per_Page);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    ChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    PageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },
    onEdit(item) {
      if (item.isEditing) item.isEditing = false;
      else this.$set(item, "isEditing", true);
    },
    deleteUser: function (rowUser) {
      this.userData = rowUser;
      // console.log(this.userData)
      this.loading = true; //skeleton true
      useNetw
        .delete("api/user/delete", {
          data: {
            userId: this.userData,
          },
        })
        .then((response) => {
          // alert("ต้องการลบหรือไม่")
          this.getData();
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    alert(rowUser) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบหรือหรือไม่ ?",
          icon: "warning",
          confirmButtonText: "ลบ",
          cancelButtonText: "ยกเลิก",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.deleteUser(rowUser);
          }
        });
    },
  },

  middleware: "authentication",
};
</script>
